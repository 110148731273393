import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';

export const IconFilters: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.625 11.75H13.375C13.858 11.75 14.25 12.1233 14.25 12.5833V15.9167C14.25 16.3767 13.858 16.75 13.375 16.75H11.625C11.142 16.75 10.75 16.3767 10.75 15.9167V12.5833C10.75 12.1233 11.142 11.75 11.625 11.75Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
      <path
        d="M17.75 14.25H14.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
      <path
        d="M10.75 14.25H2.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.375 8.25H6.625C6.142 8.25 5.75 7.87667 5.75 7.41667V4.08333C5.75 3.62333 6.142 3.25 6.625 3.25H8.375C8.858 3.25 9.25 3.62333 9.25 4.08333V7.41667C9.25 7.87667 8.858 8.25 8.375 8.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
      <path
        d="M2.25 5.75H5.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
      <path
        d="M9.25 5.75H17.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-yellow-600)"
      />
    </IconBase>
  );
};
