import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';

export const IconEye: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M10 4.5C5 4.5 3 10 3 10C3 10 5 15.5 10 15.5C15 15.5 17 10 17 10C17 10 15 4.5 10 4.5Z"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-blue-500)"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-blue-500)"
      />
    </IconBase>
  );
};
