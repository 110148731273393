import React from 'react';

export const PivotRate = [
  {
    param: 'created_at',
    type: 'string',
    description: <p>The timestamp at which the pivot interest rate object was created.</p>,
  },
  {
    param: 'effective_date',
    type: 'string',
    description: <p>The date in Pacific Time Zone (inclusive) on which the pivot interest rate will become effective.</p>,
  },
  {
    param: 'id',
    type: 'string',
    description: <p>The ID of the pivot interest rate object.</p>,
  },
  {
    param: 'idempotency_key',
    type: 'string',
    description: <p>The idempotency key specified in the pivot interest rate.</p>,
  },
  {
    param: 'product_type',
    type: 'string',
    description: <p>The product type of the pivot interest rate. Currently only <code>bank_account</code> is supported.</p>,
  },
  {
    param: 'rate',
    type: 'string',
    description: <p>The rate of the pivot interest rate object (e.g., <code>"0.045"</code> means <code>4.50%</code>).</p>,
  },
  {
    param: 'updated_at',
    type: 'string',
    description: <p>The timestamp at which the pivot interest rate object was last updated.</p>,
  },
];
