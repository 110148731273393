import React from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Typing } from '~/elements/Typing';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { InlineLink } from '~/elements/InlineLink';
import { Text } from '~/elements/NewText';
import { Heading } from '~/elements/Heading';

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Hero className={classNames(heroClasses)}>
      <Grid alignItems="center" gap={8} zIndex="10" position="relative">
        <Column gridColumn={{ _: '1 / span 12', md: '1 / span 7' }}>
          <Grid gridTemplateColumns={1} gapY={8}>
            <Heading as="h1" weight="semibold" size="600">
              Press
              <Typing text="" />
            </Heading>

            <Text size="300" color="gray-700">
              Oh hey. We prefer to spend our time shipping over talking about ourselves. And we don't use external
              agencies. But our one comms guy just loves to chat — he can answer your questions or try to work with you
              on whatever you have in mind.
            </Text>
            <Text size="300" color="gray-700">
              Shoot us an email. <InlineLink href="mailto:press@column.com?subject=Press">press@column.com</InlineLink>
            </Text>
          </Grid>
        </Column>
      </Grid>

      <div className="absolute bottom-0 left-0 right-0 overflow-hidden -top-96" style={{ height: 1200 }}>
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
