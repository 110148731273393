import React, { CSSProperties, ReactNode, useState, useEffect } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Flex } from '~/elements/Flex';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { DocsData } from '~/data/docs';
import { useHistory } from '~/utils/useHistory';

export interface NavProps {
  className?: string;
  style?: CSSProperties;
}

const Wrapper = styled.aside`
  width: 100%;
  height: 100vh;
  min-width: 248px;
  max-width: 280px;
  position: sticky;
  padding-top: 66px;
  top: 0;
  border-right: 1px solid var(--color-gray-100);
  transition: transform 0.2s, height 0.2s;

  .scroll-up & {
    transform: translateY(0);
  }

  .scroll-down & {
    transform: translateY(-66px);
    height: calc(100vh + 66px);
    margin-bottom: -66px;
  }
`;

const StyledViewport = styled(ScrollArea.Viewport)`
  padding: var(--space-2);
  height: calc(100vh - 66px);

  .scroll-down & {
    height: 100vh;
  }
`;

const StyledScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 4px;
  width: 12px;
  z-index: 10;
  &[data-orientation='vertical'] {
    width: SCROLLBAR_SIZE;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: SCROLLBAR_SIZE;
  }
`;

const StyledThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: var(--color-gray-50);
  box-shadow: 0 0 0 1px var(--color-gray-100);
  border-radius: var(--radii-pill);
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    z-index: 10;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
`;

const NavOverview = styled.ul<any>`
  display: flex;
  gap: 1px;
  flex-direction: column;
`;

const NavAPI = styled.ul<any>`
  margin: var(--space-3) 0;
  display: flex;
  gap: 1px;
  flex-direction: column;
`;

const NavItem = styled.li<{ active?: boolean }>`
  border-radius: var(--radii-2);
  transition: background-color 0.2s;

  .icon--chevron {
    transition: transform 0.1s;
    --icon-base-color: var(--color-blue-600);
  }

  > ul {
    max-height: 0;
    transition: max-height 0.15s, margin-bottom 0.15s;
    overflow: hidden;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: var(--color-blue-50);

      ${PrimaryLink} {
        ${Text} {
          color: var(--color-blue-900);
        }
        .icon-docs {
          --icon-color-docs: var(--color-blue-600);
        }
      }

      .icon--chevron {
        --icon-base-color: var(--color-blue-900);
        transform: rotate(90deg);
      }

      > ul {
        max-height: 500px;
        transition: max-height 0.2s, margin-bottom 0.2s;
        margin-bottom: var(--space-4);
      }
    `}
`;

const PrimaryLink = styled.a<any>`
  width: 100%;
  display: flex;
  gap: var(--space-2);
  justify-content: space-between;
  padding: var(--space-3);
  border-radius: var(--radii-2);
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(var(--rgb-blue-50), 0.5);
  }

  &:active {
    background-color: var(--color-blue-50);
  }

  ${Text} {
    color: var(--color-blue-900);
  }

  .icon-docs {
    width: 20px;
    height: 20px;
    --icon-color-docs: var(--color-blue-600);
  }
`;

const SecondaryNav = styled.ul<any>`
  padding: 0 var(--space-4);
`;

const SecondaryLink = styled.a<any>`
  width: 100%;
  display: flex;
  padding: var(--space-2) var(--space-3);
  position: relative;

  ${Text} {
    transition: color 0.2s;
  }

  &:hover {
    ${Text} {
      color: var(--color-blue-800);
    }
  }

  &:active {
    ${Text} {
      color: var(--color-blue-900);
    }
  }

  &::before,
  &::after {
    content: '';
    width: 1px;
    top: 0;
    bottom: 0;
    right: 100%;
    display: block;
    position: absolute;
    background-color: var(--color-blue-100);
  }

  &::after {
    top: var(--space-4);
    bottom: var(--space-4);
    transition: top 0.25s, bottom 0.25s;
  }

  ${({ active }) =>
    active &&
    css`
      &::after {
        top: 0;
        bottom: 0;
        background-color: var(--color-blue-700);
        transition: top 0.25s, bottom 0.25s;
      }

      ${Text} {
        color: var(--color-blue-900);
      }
    `};
`;

interface Item {
  icon?: ReactNode;
  title: string;
  url: string;
}

interface SubNavProps {
  items: Item[];
  parentUrl?: string;
  scroll?: boolean;
  currentHash?: string;
}

const SubNav: React.FC<SubNavProps> = (props) => {
  const path = useHistory();
  const isApi = path?.startsWith('/docs/api/');
  const active = isApi ? props.currentHash : path?.replace(/^\/?|\/?$/g, '');

  return (
    <SecondaryNav>
      {props.items.map((item: Item, index: number) => {
        const url = `${props.parentUrl}/${item.url}`;

        const elementProps = props.scroll
          ? {
              href: url,
            }
          : {
              to: url,
              as: Link,
            };

        return (
          <li key={index}>
            <SecondaryLink {...elementProps} active={active === url.replace(/^\/?|\/?$/g, '')}>
              <Text size="100">{item.title}</Text>
            </SecondaryLink>
          </li>
        );
      })}
    </SecondaryNav>
  );
};

export const Sidebar: React.FC<NavProps> = () => {
  const [hash, setHash] = useState<string>(typeof window === 'undefined' ? '' : window.location.hash);
  const path = useHistory();
  const [isClient, setClient] = useState<boolean>(false);

  useEffect(() => {
    setClient(true);
  }, []);

  const handleHashChange = (e: any) => {
    setHash(e.detail.url);
  };

  const handleWindowHashChange = () => {
    if (typeof window === 'undefined') {
      return;
    }

    setHash(window.location.hash);
  };

  useEffect(() => {
    window.addEventListener('historyChanged', handleHashChange);
    window.addEventListener('hashchange', handleWindowHashChange);

    return () => {
      window.removeEventListener('historyChanged', handleHashChange);
      window.removeEventListener('hashchange', handleWindowHashChange);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          dataApi: allFile(filter: { sourceInstanceName: { eq: "dataApi" } }, sort: { fields: childrenMdx___slug }) {
            edges {
              node {
                childMdx {
                  slug
                  frontmatter {
                    navigation
                  }
                }
              }
            }
          }
          extendedApi: allFile(filter: { sourceInstanceName: { eq: "extendedApi" } }, sort: { fields: childrenMdx___slug }) {
            edges {
              node {
                childMdx {
                  slug
                  frontmatter {
                    navigation
                  }
                }
              }
            }
          }
        }
      `}
      render={(queryData) => {
        const dataApiArr: { [key: string]: any } = [];
        const extendedApiArr: { [key: string]: any } = [];

        // Process dataApi edges
        queryData.dataApi.edges.map((edge: any) => {
          const data = edge.node.childMdx;
          const paths = data.slug.split('/');
          const pathFirst = paths[0].split('-');
          const pathSecond = paths[1].split('-');
          delete pathFirst[0];
          delete pathSecond[0];

          const pathFirstString = pathFirst.filter((a: any) => a).join('-');
          const pathSecondString = pathSecond.filter((a: any) => a).join('-');

          if (!dataApiArr[pathFirstString]) {
            dataApiArr[pathFirstString] = {};
            dataApiArr[pathFirstString].title = pathFirstString
              .replace('-', ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())
              .replace('Ach', 'ACH')
              .replace('Rfp', 'RFP')
              .replace('Return-request', 'Return Request');

            dataApiArr[pathFirstString].base = pathFirstString;
            dataApiArr[pathFirstString].children = [];
          }

          dataApiArr[pathFirstString].children.push({
            url: pathSecondString,
            title: data.frontmatter.navigation,
          });

          if (!dataApiArr[pathFirstString].url) {
            dataApiArr[pathFirstString].url = dataApiArr[pathFirstString].children[0].url;
          }
        });

        // Process extendedApi edges
        queryData.extendedApi.edges.map((edge: any) => {
          const data = edge.node.childMdx;
          const paths = data.slug.split('/');
          const pathFirst = paths[0].split('-');
          const pathSecond = paths[1].split('-');
          delete pathFirst[0];
          delete pathSecond[0];

          const pathFirstString = pathFirst.filter((a: any) => a).join('-');
          const pathSecondString = pathSecond.filter((a: any) => a).join('-');

          if (!extendedApiArr[pathFirstString]) {
            extendedApiArr[pathFirstString] = {};
            extendedApiArr[pathFirstString].title = pathFirstString
              .replace('-', ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())
              .replace('Ach', 'ACH')
              .replace('Rfp', 'RFP');

              extendedApiArr[pathFirstString].base = pathFirstString;
              extendedApiArr[pathFirstString].children = [];
          }

          extendedApiArr[pathFirstString].children.push({
            url: pathSecondString,
            title: data.frontmatter.navigation,
          });

          if (!extendedApiArr[pathFirstString].url) {
            extendedApiArr[pathFirstString].url = extendedApiArr[pathFirstString].children[0].url;
          }
        });

        const showExtendedApiInSidebar = false;

        return (
          <Wrapper as="aside">
            <ScrollArea.Root>
              <StyledViewport>
                <Nav key={isClient ? 'client' : 'server'}>
                  <section>
                    <NavOverview>
                      {DocsData.map((item, index: number) => {
                        return (
                          <NavItem
                            key={index}
                            active={
                              item?.children && item.children.length ? path?.includes(item.url) : path === item.url
                            }
                          >
                            <PrimaryLink to={item.url} as={Link}>
                              <Flex gap={2} width="full">
                                {item.icon}
                                <Text size="200" weight="medium">
                                  {item.title}
                                </Text>
                              </Flex>
                              {item?.children && item.children.length > 0 && <Icon.ChevronRight />}
                            </PrimaryLink>
                            {item?.children && item.children.length > 0 && (
                              <SubNav parentUrl={item.url} items={item.children} currentHash={hash} />
                            )}
                          </NavItem>
                        );
                      })}
                    </NavOverview>
                  </section>

                  <section>
                    <Heading as="h4" size="200" mb={4} ml={3} color="gray-400" textTransform="uppercase">
                      Column API
                    </Heading>
                    <NavAPI>
                      {Object.values(dataApiArr).filter((item: any) => !item.title.toLowerCase().includes('interest')).map((item: any, index: number) => {
                        const base = `#${item.base}`;
                        const isApi = path?.startsWith('/docs/api/');

                        const elementProps = isApi
                          ? {
                              href: `${base}/${item.url}`,
                            }
                          : {
                              as: Link,
                              to: `/docs/api/${base}/${item.url}`,
                            };

                        return (
                          <NavItem
                            key={index}
                            active={item.children?.findIndex((child: any) => `${base}/${child.url}` === hash) !== -1}
                          >
                            <PrimaryLink {...elementProps}>
                              <Text size="200" weight="medium">
                                {item.title}
                              </Text>
                              <Icon.ChevronRight />
                            </PrimaryLink>
                            <SubNav parentUrl={base} items={item.children} currentHash={hash} scroll />
                          </NavItem>
                        );
                      })}
                    </NavAPI>
                  </section>

                  {showExtendedApiInSidebar && (
                    <section>
                      <Heading as="h4" size="200" mb={4} ml={3} color="gray-400" textTransform="uppercase">
                        Extended Column API
                      </Heading>
                      <NavAPI>
                        {Object.values(extendedApiArr).map((item: any, index: number) => {
                          const base = `#${item.base}`;
                          const isApi = path?.startsWith('/docs/extended-api/');

                          const elementProps = isApi
                            ? {
                                href: `${base}/${item.url}`,
                              }
                            : {
                                as: Link,
                                to: `/docs/extended-api/${base}/${item.url}`,
                              };

                          return (
                            <NavItem
                              key={index}
                              active={item.children?.findIndex((child: any) => `${base}/${child.url}` === hash) !== -1}
                            >
                              <PrimaryLink {...elementProps}>
                                <Text size="200" weight="medium">
                                  {item.title}
                                </Text>
                                <Icon.ChevronRight />
                              </PrimaryLink>
                              <SubNav parentUrl={base} items={item.children} currentHash={hash} scroll />
                            </NavItem>
                          );
                        })}
                      </NavAPI>
                    </section>
                  )}
                </Nav>
              </StyledViewport>
              <StyledScrollbar orientation="vertical">
                <StyledThumb />
              </StyledScrollbar>
            </ScrollArea.Root>
          </Wrapper>
        );
      }}
    />
  );
};
