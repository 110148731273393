import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../../Base';

export const IconClose: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon--stroke', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M15 5L5 15"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-red-500)"
      />
      <path
        d="M15 15L5 5"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="var(--color-red-500)"
      />
    </IconBase>
  );
};
