import React from 'react';

import { ConfigTier } from './config-tier';

export const ConfigSnapshot = [
  {
    param: 'accrual_method',
    type: 'string',
    description: <p>The accrual method by which an account will accrue interest. Possible values: <code>actual_360</code>, <code>actual_365</code>, and <code>actual_actual</code>.</p>,
  },
  {
    param: 'ceiling_rate',
    type: 'string',
    description: <p>The maximum value of the effective interest rate (<code>4.50%</code> would be represented by <code>"0.045"</code>, <a href='/docs/interest/configs'>read more</a>).</p>,
  },
  {
    param: 'effective_date',
    type: 'string',
    description: <p>The date in Pacific Time Zone (inclusive) on which this interest config will become effective.</p>,
  },
  {
    param: 'floor_rate',
    type: 'string',
    description: <p>The minimum value of the effective interest rate (<code>0.50%</code> would be represented by <code>"0.005"</code>, <a href='/docs/interest/configs'>read more</a>).</p>,
  },
  {
    param: 'tiers',
    type: 'array of objects',
    description: <p>Interest config tiers in ascending order of their thresholds.</p>,
    object: [...ConfigTier],
  },
];
