import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsRealtime: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        d="M3.75011 17.19C3.57011 17.19 3.39011 17.16 3.21011 17.09C2.40011 16.79 1.99011 15.89 2.29011 15.08L5.28011 6.98998C5.69011 5.86998 6.73011 5.14998 7.92011 5.14998C9.11011 5.14998 10.1401 5.86998 10.5601 6.98998L12.0901 11.12L14.7901 3.82998C15.0901 3.01998 15.9901 2.60998 16.8001 2.90998C17.6101 3.20998 18.0201 4.10998 17.7201 4.91998L14.7301 13.01C14.3201 14.13 13.2801 14.85 12.0901 14.85C10.9001 14.85 9.87011 14.13 9.45011 13.01L7.92011 8.87998L5.22011 16.17C4.99011 16.8 4.39011 17.19 3.75011 17.19Z"
        fill="url(#paint0_linear_527_343)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_527_343"
          x1="0.790111"
          y1="9.99998"
          x2="16.4201"
          y2="9.99998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
