import React, { PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';
import { compose, margin, maxWidth, MarginProps, MaxWidthProps } from 'styled-system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Prism as SyntaxHighlighter, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { default as columnDark } from './column-syntax-alt';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Box } from '~/elements/Box';

interface CodeProps extends SyntaxHighlighterProps, MarginProps, MaxWidthProps {
  language?: string;
  className?: string;
  showLineNumbers?: boolean;
  startingLineNumber?: number;
  title?: string;
  noWrapper?: boolean;
  copy?: boolean;
  fullHeight?: boolean;
  maxHeight?: string;
}

const Wrapper = styled.div`
  display: inherit;
  ${compose(margin, maxWidth)};
`;

const CodeTitle = styled.div`
  padding: var(--space-4);
  border-bottom: 1px solid rgba(var(--rgb-white), 0.2);
  position: relative;
  z-index: 1;
`;

const CodeBlock = styled.div<SyntaxHighlighterProps>`
  position: relative;
  border-radius: 12px;
  background: var(--color-blue-800);
  box-shadow: var(--shadow-product);
  margin-bottom: var(--space-4);
  overflow: hidden;
  transform: translateZ(0);

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--space-8);
    border-right: var(--space-2) solid var(--color-blue-800);
    background: linear-gradient(270deg, var(--color-blue-800) 10%, rgba(var(--rgb-blue-800), 0) 100%);
  }
  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--space-7);
    border-bottom: 8px solid var(--color-blue-800);
    background: linear-gradient(0deg, var(--color-blue-800) 10%, rgba(var(--rgb-blue-800), 0) 100%);

    ${({ fullHeight }) =>
      fullHeight === 'true' &&
      css<SyntaxHighlighterProps>`
        display: block;
      `}

    ${({ fullHeight }) =>
      fullHeight === 'false' &&
      css<SyntaxHighlighterProps>`
        display: none;
        height: 0;
      `}
  }

  ${({ noWrapper }) =>
    noWrapper === true &&
    css`
      background: transparent;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
    `}
`;

const StyledViewport = styled(ScrollArea.Viewport)<any>`
  max-width: 100%;
  color: #fff;
  white-space: nowrap;

  ${({ fullHeight }) =>
    fullHeight === 'true' &&
    css<SyntaxHighlighterProps>`
      max-height: ${(props) => props.maxHeight || '216px'};
    `}

  ${({ fullHeight }) =>
    fullHeight === 'false' &&
    css<SyntaxHighlighterProps>`
      max-height: none;
    `}
`;

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  padding: var(--space-4) var(--space-16) var(--space-5) var(--space-4);
`;

const StyledScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 8px;

  z-index: 10;
  &[data-orientation='vertical'] {
    width: SCROLLBAR_SIZE;
    flex-direction: row;
    height: auto;
    width: 20px;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: SCROLLBAR_SIZE;
    height: 20px;
  }
`;

const StyledThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: rgba(var(--rgb-white), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.65);
  border-radius: var(--radii-pill);
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    z-index: 10;
  }
`;

const CopyButton = styled(CopyToClipboard)`
  position: relative;
  z-index: 2;
  cursor: pointer;
  box-shadow: 0 0 4px 4px var(--color-blue-800);
  background-color: var(--color-blue-800);
  box-shadow: 0 0 8px 8px rgba(var(--rgb-blue-800), 0.75);
  background-color: rgba(var(--rgb-blue-800), 0.75);
`;

const CopyIcon = styled(Icon.Copy)`
  --icon-base-color: #fff;
  --icon-mask-color: var(--color-blue-800);
  width: 24px;

  .icon-copy-front,
  .icon-copy-back {
    transform: translate3d(0);
    transition: transform 0.2s;
  }

  .icon-copy-check {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    transition: stroke-dashoffset 0.05s;
    transition-delay: 0.1s;
  }

  &.copied {
    .icon-copy-front {
      transform: translate3d(-3px, -2px, 0);
      transition: transform 0.2s;
    }
    .icon-copy-back {
      transform: translate3d(3px, 2px, 0);
      transition: transform 0.2s;
    }

    .icon-copy-check {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.2s;
      transition-delay: 0.15s;
    }
  }
`;

export const Code: React.FC<PropsWithChildren<CodeProps>> = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  const styleProps: SyntaxHighlighterProps = {
    fullHeight: props.fullHeight ?? 'true',
  };

  return (
    <Wrapper className={props.className} {...props} data-nosnippet>
      <CodeBlock {...styleProps} noWrapper={props.noWrapper}>
        {props.title ? (
          <CodeTitle>
            <Heading as="h4" size="200" weight="medium" color="white" mr={8}>
              {props.title}
            </Heading>
          </CodeTitle>
        ) : null}

        {props.copy ? (
          <Box position="absolute" top={3} right={4}>
            <CopyButton text={(props.children ?? '').toString()} onCopy={() => setIsCopied(true)}>
              <button aria-label="Copy this code to clipboard">
                <CopyIcon className={isCopied ? 'copied' : ''} />
              </button>
            </CopyButton>
          </Box>
        ) : null}

        <ScrollArea.Root>
          <StyledViewport {...styleProps} {...props}>
            <StyledSyntaxHighlighter
              language={props.language ?? 'shell'}
              showLineNumbers={props.showLineNumbers ?? false}
              startingLineNumber={props.startingLineNumber ?? 1}
              style={columnDark}
            >
              {props.children}
            </StyledSyntaxHighlighter>
          </StyledViewport>
          <StyledScrollbar orientation="horizontal">
            <StyledThumb />
          </StyledScrollbar>
          <StyledScrollbar orientation="vertical">
            <StyledThumb />
          </StyledScrollbar>
        </ScrollArea.Root>
      </CodeBlock>
    </Wrapper>
  );
};

Code.defaultProps = {
  copy: true,
};
