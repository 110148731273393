import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkdownStyles } from '~/elements/Markdown';
import { MarkdownInfo } from '~/components/Markdown/Info';
import { MarkdownContent } from '~/components/Markdown/Content';
import { MarkdownSyntax } from '~/components/Markdown/Syntax';
import { MarkdownEndpoint } from '~/components/Markdown/Endpoint';
import { MarkdownParameters } from '~/components/Markdown/Parameters';
import { MarkdownRequest } from '~/components/Markdown/Request';
import { MarkdownResponse } from '~/components/Markdown/Response';
import { MarkdownHeadline } from '~/components/Markdown/Headline';
import { Layout } from '~/components/docs/Layout';

const components = {
  Info: MarkdownInfo,
  Content: MarkdownContent,
  Syntax: MarkdownSyntax,
  Endpoint: MarkdownEndpoint,
  Parameters: MarkdownParameters,
  Request: MarkdownRequest,
  Response: MarkdownResponse,
  Headline: MarkdownHeadline,
};

const APIDocsContainer = styled(MarkdownStyles)`
  display: grid;
  gap: var(--space-8);
`;

const EntryContainer = styled.div`
  margin: 0 auto;
  display: grid;
  justify-items: end;
  grid-template-columns: 3fr minmax(380px, 2fr);
`;

const SectionContainer = styled.div`
  margin-bottom: 2rem;
`;

// Helper function to render each MDX document
const renderEntries = (edges: any[]) =>
  edges.map((edge, index) => {
    const data = edge.node.childMdx;
    const paths = data.slug.split('/');
    const pathFirst = paths[0].split('-');
    const pathSecond = paths[1].split('-');
    // Remove the first element of the arrays (if needed)
    delete pathFirst[0];
    delete pathSecond[0];
    const newSlug = `${pathFirst.filter(Boolean).join('-')}/${pathSecond.filter(Boolean).join('-')}`;

    return (
      <InView
        key={`${newSlug}-${index}`}
        id={newSlug}
        rootMargin="0px 0px -100% 0px"
        onChange={(inView) => {
          if (!inView || typeof window === 'undefined' || !history.pushState) {
            return;
          }
          window.dispatchEvent(
            new CustomEvent('historyChanged', {
              detail: {
                url: `#${newSlug}`,
              },
            })
          );
          history.pushState(null, '', `#${newSlug}`);
        }}
      >
        <EntryContainer>
          <MDXRenderer>{data.body}</MDXRenderer>
        </EntryContainer>
      </InView>
    );
  });

const APIIndexPage: React.FC = (props: any) => {
  const { extendedApi } = props.data;

  return (
    <Layout>
      <Helmet>
        <title>Column — Documentation</title>
        <meta
          name="description"
          content="Learn how to utilize Column's robust banking APIs to build new financial services."
        />
      </Helmet>
      <APIDocsContainer>
        {/* Section for Data API */}
        <SectionContainer>
          <MDXProvider components={components}>
            {renderEntries(extendedApi.edges)}
          </MDXProvider>
        </SectionContainer>
      </APIDocsContainer>
    </Layout>
  );
};

export default APIIndexPage;

export const query = graphql`
  query {
    extendedApi: allFile(
      filter: { sourceInstanceName: { eq: "extendedApi" } }
      sort: { fields: childrenMdx___slug }
    ) {
      edges {
        node {
          childMdx {
            body
            slug
          }
        }
      }
    }
  }
`;
