import { IconCheck } from './Check';
import { IconEye } from './Eye';
import { IconClose } from './Close';
import { IconSettings } from './Settings';
import { IconFilters } from './Filters';

export const IconRoles = {
  RolesCheck: IconCheck,
  RolesEye: IconEye,
  RolesClose: IconClose,
  RolesSettings: IconSettings,
  RolesFilters: IconFilters,
};