import { css } from 'styled-components';
import { sm, md } from '~/ds/mixins/breakpoints';

export const Blog = css`
  .blog-container {
    @media ${md} {
      display: grid;
      grid-template-columns: var(--cols-12);
    }
  }

  .blog-post {
    grid-column: var(--span-6);
    grid-column-start: 4;
    border-color: var(--color-gray-100);
    border-top-width: 1px;
    padding: var(--space-12) 0 var(--space-16) 0;
  }

  .blog-closer {
    border-color: var(--color-gray-100);
    border-bottom-width: 1px;
    padding-bottom: var(--space-4);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .footnote-wrap {
    border-color: var(--color-gray-100);
    border-top-width: 1px;
    padding-top: var(--space-4);
    padding-bottom: var(--space-16);
  }

  .social-share {
    display: none;
    gap: var(--space-3);
    color: var(--color-blue-800);
    z-index: 20;
    margin-bottom: 1px;

    @media ${md} {
      display: flex;
      align-items: center;
    }

    &-follow {
      flex-direction: column;
      position: fixed;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      margin: -8px 0 0 0;

      & p {
        writing-mode: vertical-rl;
      }
    }

    & a {
      width: var(--space-5);
      height: var(--space-5);
      transition: 0.1s color ease;

      &.twitter:hover {
        color: #00acee;
      }

      &.linkedin:hover {
        color: #0e76a8;
      }
    }

    & p {
      transition: 0.2s 0.1s transform ease, 0.2s 0.1s opacity;
      transform-origin: 50% 75%;
    }

    & a.twitter {
      transition: 0.2s 0.05s transform ease, 0.2s 0.05s opacity;
    }

    & a.linkedin {
      transition: 0.2s transform ease, 0.2s opacity;
    }

    &.inview--share {
      & p {
        transform: scale(0.5) translateX(-24px);
        opacity: 0;
      }
      & a.twitter {
        transform: scale(0.5) translateX(-24px);
        opacity: 0;
      }

      & a.linkedin {
        transform: scale(0.5) translateX(-24px);
        opacity: 0;
      }
    }
  }

  .blog-sign-up {
    margin-top: var(--space-16);
    margin-bottom: var(--space-16);
    background-color: var(--color-gray-50);
    border-width: 1px;
    border-color: var(--color-gray-100);
    padding-right: var(--space-12);
    padding-left: var(--space-12);
    padding-top: var(--space-10);
    padding-bottom: var(--space-10);
    border-radius: 12px;

    & h2 {
      color: var(--color-gray-900);
      margin-top: 0;
    }

    & button {
      margin-top: 16px;
      width: calc(100% - 2px);
    }

    & .email-error {
      margin-bottom: var(--space-2);
      color: var(--color-orange-600);
      display: none;
    }

    & .email-success {
      margin-top: var(--space-2);
      border-radius: var(--radii-default);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      height: 46px;
      color: var(--color-green-700);
      background-color: var(--color-green-100);
      border: 1px solid var(--color-green-200);
      display: none;

      @media ${md} {
        justify-content: flex-start;
      }
    }

    &-error {
      & .email-error {
        display: block;
      }
      input {
        border-color: var(--color-orange-600);
      }
    }

    &-success {
      & .email-success {
        display: flex;
      }
      input,
      button {
        display: none;
      }
    }

    @media ${md} {
      & input {
        max-width: 280px;
        margin: 0;
      }

      & input {
        margin-top: 0;
      }

      & button {
        margin-top: 1px;
        width: auto;
      }
    }
  }

  .blog-continue {
    border-top: 1px var(--color-gray-900);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: var(--space-4);

    & > div {
      width: 33.333333%;
    }

    & h4 {
      margin-top: var(--space-3);
      color: var(--color-gray-900);
      font-weight: var(--font-medium);
    }

    & a:hover h4 {
      color: var(--blog-800);
    }
  }

  .blog-nav {
    grid-column: var(--span-12);
    margin-top: var(--space-8);
    display: flex;
    justify-content: flex-start;
    gap: var(--space-4);
    z-index: 10;

    & a {
      font-weight: var(--font-medium);
      display: block;
      padding-bottom: var(--space-3);
      margin: 0;
      color: var(--color-gray-500);
      transition: 0.2s color ease;
    }

    & a:hover {
      color: var(--color-gray-600);
    }

    & a.active,
    & a.active:hover {
      color: var(--color-blue-700);
      box-shadow: 0 1px 0 rgba(var(--rgb-blue-700));
    }
  }

  .blog-post-nav {
    grid-column: var(--span-12);
    padding-top: var(--space-2);
    border-color: var(--color-gray-100);
    border-top-width: 1px;
  }

  .blog-home {
    color: var(--color-blue-800);
    border-radius: var(--radii-full);
    padding-right: var(--space-2);
    padding-left: var(--space-2);
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
    font-weight: var(--font-semibold);
    margin-left: var(--space-n2);
    line-height: 1rem;

    & svg {
      margin: -2px 4px 0 0;
    }

    &:hover {
      background-color: var(--color-blue-50);
    }
  }

  .blog-category {
    background-color: var(--blog-100);
    color: var(--blog-700);
    display: inline-block;
    border-radius: var(--radii-full);
    padding-right: var(--space-2);
    padding-left: var(--space-2);
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
    font-weight: var(--font-semibold);
    margin-left: var(--space-n0-5);
    line-height: 1rem;

    /* &:hover {
    background-color: var(--blog-200);
  } */
  }

  .blog-title {
    margin-top: var(--space-8) !important;
    color: var(--blog-800);
  }

  .blog-date {
    color: var(--color-gray-900);
    line-height: 1rem;
  }

  .blog-author-image {
    width: var(--space-10);
    height: var(--space-10);
    border-radius: var(--radii-full);
    background-color: var(--color-gray-300);

    & img {
      border-radius: var(--radii-full);
    }
  }

  .blog-author-name {
    color: var(--color-gray-900);
    font-weight: var(--font-semibold);
    margin: 0;
    display: block;
    line-height: 1.25em;
  }

  .blog-author-title {
    margin: 0;
    display: block;
    line-height: 1.25em;
  }

  .blog-card,
  %blog-card {
    margin-bottom: var(--space-8);
    background-color: rgba(var(--rgb-gray-50), 0.5);
    cursor: pointer;
    display: block;
    border-radius: 12px;
    transition: 0.1s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: var(--color-gray-50);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);

      .blog-card-top {
        background-color: var(--color-gray-100);
      }
    }
  }

  .blog-card {
    margin-right: var(--space-n12);
    margin-left: var(--space-n12);

    & h2 {
      color: var(--color-gray-900);
    }

    &-top {
      display: flex;
      place-content: space-between;
      place-items: center;
      padding: var(--space-2);
      padding-left: var(--space-3);
      border-radius: 12px 12px 0 0;
      background-color: var(--color-gray-50);
      border-bottom: 1px solid var(--color-gray-200);
      transition: 0.1s ease;
    }

    &-content {
      padding: var(--space-8) var(--space-16) var(--space-10) var(--space-12);
    }
  }

  .blog-card,
  .blog-preview,
  .blog-card-changelog {
    font-weight: var(--font-book);

    & a {
      text-decoration: underline;
    }

    & p a {
      font-weight: var(--font-medium);
      text-decoration: none;
      color: var(--blog-800);
      background-image: linear-gradient(var(--blog-800), var(--blog-800));
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;

      &:hover {
        background-size: 100% 2px;
      }
    }

    & a code {
      text-decoration: none;
      color: var(--blog-800);
    }

    & li {
      margin-bottom: var(--space-4);
      margin-left: var(--space-6);

      &:last-child {
        margin-bottom: 0;
      }
    }

    & li::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 0.65em;
      color: var(--blog-500);
      background-color: var(--color-gray-400);
      width: 10px;
      height: 1px;
    }

    & ul {
      margin-top: 1em;
    }

    & ol > li {
      position: relative;
    }

    & ol > li > * {
      display: inline;
    }

    & ol li ol {
      display: block;
      padding-left: 2rem;
    }

    & ul > li {
      position: relative;
    }

    & ul li ul {
      padding-left: 2rem;
    }

    & ul ul,
    & ul ol,
    & ol ul,
    & ol ol {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    & code {
      background-color: var(--blog-50);
      color: var(--blog-800);
      padding-right: var(--space-1);
      padding-left: var(--space-1);
      border-radius: var(--radii-sm);
      position: relative;
      font-size: 0.875rem;
      line-height: 1rem;
      top: -1px;
    }

    & b {
      font-weight: 600;
    }

    & .blog-author-image {
      width: var(--space-5);
      height: var(--space-5);

      &::before {
        content: '';
        z-index: 10;
        position: absolute;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      }
    }

    & .blog-author-name,
    & .blog-date {
      font-weight: var(--font-book);
    }

    & .blog-category:hover {
      background-color: var(--blog-100);
    }
  }

  .blog-card-video {
    display: block;
    position: relative;
    border-radius: var(--radii-sm);
    cursor: pointer;
    width: 100%;
    max-width: 240px;
    margin: var(--space-8) 0;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }

  .blog-video-poster {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 52.5%;

    & img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: var(--radii-sm);
      background-size: cover;
    }
  }

  .blog-video-play {
    width: 100%;
    position: absolute;
    border-radius: var(--radii-sm);
    padding: var(--space-4) var(--space-6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(var(--rgb-blue-900), 0.4);
    transition: 0.2s background-color ease;
    z-index: 10;

    & p {
      color: var(--color-white);
      max-width: var(--screen-md);
      z-index: 10;
      line-height: 1.125em;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      & span {
        color: rgba(var(--rgb-white), 0.6);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: var(--radii-sm);
      background-image: url(/images/blog/play.svg);
      background-size: 64px 64px;
      background-repeat: no-repeat;
      background-position: center center;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 -80px 64px -32px rgba(0, 0, 0, 0.25);
    }

    &:hover {
      background-color: rgba(var(--rgb-blue-900), 0.3);
    }
  }

  .blog-video-modal {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);

    & video {
      position: relative;
      border-radius: var(--radii-default);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
      max-width: calc(90vw - 40px);
      max-height: calc(90vh - 40px);

      &:focus {
        outline: none;
      }
    }

    &-close {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 8px;
      right: 8px;
      border-radius: 8px;
      z-index: 100;
      cursor: pointer;
      transition: 0.2s background-color ease;
      background-color: rgba(var(--rgb-black), 0.2);

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1px 0 0 -8px;
        width: 16px;
        height: 2px;
        z-index: 10;
        transition: 0.2s background-color ease;
        background-color: rgba(var(--rgb-white), 0.6);
      }

      &::before {
        transform: rotateZ(45deg);
      }

      &::after {
        transform: rotateZ(-45deg);
      }

      &:hover {
        background-color: rgba(var(--rgb-black), 0.4);

        &::before,
        &::after {
          background-color: var(--color-white);
        }
      }
    }

    &-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .blog-card-changelog {
    position: relative;
    padding-left: var(--space-4);
    padding-right: var(--space-4);

    @media ${md} {
      display: grid;
      grid-template-columns: var(--cols-12);
      padding-left: 0;
      padding-right: 0;
    }

    & p {
      overflow-wrap: break-word;
      word-wrap: break-word;
      margin-bottom: 1rem;
    }

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: var(--blog-800);
      margin-top: 2rem;
    }

    &:last-child {
      &::before {
        bottom: var(--space-n16);
      }
    }

    & .icon {
      width: var(--space-5);
      height: var(--space-5);
      margin-right: var(--space-1);
      color: var(--color-gray-900);
    }

    & h2 {
      margin-top: 0;
      margin-bottom: var(--space-4);
      @media ${md} {
        padding: var(--space-12) 0 0 0;
      }
    }

    & h5 {
      margin-top: 0;
      margin-bottom: 0;
    }

    & a > h2 {
      transition: color 0.2s ease;
    }

    & a > h2:hover {
      color: var(--blog-800);
    }

    &:last-child {
      padding-bottom: var(--space-24);
    }

    & .blog-date {
    }

    & .blog-card-content {
      padding: 32px 0 64px 32px;

      &::before {
        content: '';
        position: absolute;
        background-color: var(--color-blue-50);
        width: 4px;
        left: 0;
        top: -8px;
        bottom: -24px;
        border-radius: 2px;
      }

      &::after {
        content: '';
        display: block;
        background-color: var(--color-gray-100);
        height: 1px;
        width: 100%;
        margin: 64px 0 0 0;
      }

      @media ${md} {
        grid-column: var(--span-8);
        grid-column-start: 3;
        padding: 0 24px 0 48px;

        &::before {
          top: 60px;
          bottom: -60px;
        }
        &::after {
          margin: 48px 0 0 0;
        }
      }
    }
    &:last-child {
      & .blog-card-content {
        &::before {
          background: linear-gradient(180deg, rgba(var(--rgb-blue-50)), rgba(var(--rgb-white)));
          bottom: -48px;
        }
        &::after {
          display: none;
        }
      }
    }
  }

  .blog-card-sticky {
    position: relative;
    align-self: flex-start;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;

    @media ${md} {
      position: sticky;
      grid-column: var(--span-2);
      top: var(--space-32);
    }

    .blog-date {
      background-color: var(--color-blue-50);
      margin-bottom: 0;
      color: rgba(var(--rgb-blue-800), 0.6);
      border-radius: 12px;
      padding: 4px 8px;
      margin: 0 0 0 28px;

      &::before {
        content: '';
        position: absolute;
        border-radius: var(--radii-full);
        background-color: var(--color-blue-600);
        z-index: 20;
        width: 6px;
        height: 6px;
        left: -1px;
        top: 9px;
        box-shadow: 0 0 8px 8px #fff;
      }
    }

    @media ${md} {
      margin: 48px 0 48px 0;
      text-align: right;
      justify-content: flex-end;

      .blog-date {
        margin: 0 12px 0 -20px;

        &::before {
          right: -5px;
          left: auto;
        }
      }
    }
  }

  .blog-card-subhead {
    display: flex;
    align-items: center;
    margin-top: var(--space-10);
    margin-bottom: var(--space-5);
  }

  .blog-preview {
    display: grid;
    grid-template-columns: var(--cols-1);
    row-gap: var(--space-4);
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);
    border-color: var(--color-gray-100);
    border-top-width: 1px;
    padding-right: var(--space-4);
    padding-left: var(--space-4);

    @media ${sm} {
      padding-right: var(--space-16);
      padding-left: var(--space-16);
    }

    @media ${md} {
      grid-template-columns: var(--cols-12);
      padding-right: 0;
      padding-left: 0;
    }

    & * {
      transition: 0.1s color ease;
    }

    & h2 {
      color: var(--color-gray-900);
      color: var(--blog-800);
    }

    &:hover * {
      color: var(--blog-600);
    }

    & .blog-author-image {
      width: var(--space-5);
      height: var(--space-5);
    }

    &:nth-child(4) {
      border-style: none;
    }
  }

  .changelog-callout {
    background-color: var(--color-blue-800);
    grid-column: var(--span-12);
    padding: var(--space-12) var(--space-16) var(--space-16);
    margin-top: var(--space-4);
    margin-bottom: var(--space-4);
    border-radius: var(--space-3);

    @media ${md} {
      padding-bottom: var(--space-12);
    }

    h2,
    h3 {
      color: var(--color-white);
    }

    p {
      color: rgba(var(--rgb-white), 0.6);
      transition: 0.1s opacity ease;
      transition: color 0.2s;
    }

    a:hover p {
      color: rgba(var(--rgb-white), 0.8);
    }

    .changelog-card-preview {
      padding-left: var(--space-5);
      position: relative;
      max-width: var(--screen-md);

      @media ${md} {
        padding-left: 0;
      }

      &::before {
        content: '';
        position: absolute;
        background-color: var(--color-blue-700);
        border-radius: var(--radii-default);
        width: 4px;
        top: 8px;
        bottom: -64px;
        left: 0;
      }

      @media ${md} {
        &::before {
          display: none;
        }
      }
    }

    & .blog-date {
      margin-bottom: var(--space-5);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        border-radius: var(--radii-full);
        background-color: var(--color-blue-400);
        z-index: 20;
        width: 6px;
        height: 6px;
        left: -21px;
        bottom: 4px;
        box-shadow: 0 0 4px 4px rgba(var(--rgb-blue-500), 0.33), 0 0 0 2px rgba(var(--rgb-blue-800));
        box-shadow: 0 0 4px 4px rgba(var(--rgb-blue-800));
      }

      @media ${md} {
        padding-bottom: var(--space-4);
        margin-bottom: var(--space-4);

        &::before {
          left: 0;
          bottom: -3px;
        }

        &::after {
          content: '';
          position: absolute;
          background-color: var(--color-blue-700);
          height: 4px;
          bottom: -2px;
          left: 0;
          right: var(--space-n20);
        }
      }
    }
  }

  .changelog-image {
    margin-top: var(--space-8);
    margin-bottom: var(--space-8);
    display: block;
    border-radius: var(--radii-sm);
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 52.5%;

    & img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: var(--radii-sm);
      background-size: cover;
    }
  }
`;
