import { IconChevron } from './Chevron';
import { IconArrow } from './Arrow';
import { IconMisc } from './Misc';
import { IconProduct } from './Product';
import { IconDevelopers } from './Developers';
import { IconBlog } from './Blog';
import { IconDocs } from './Docs';
import { IconBrand } from './Brand';
import { IconRoles } from './Misc/Roles';

export const Icon = {
  ...IconChevron,
  ...IconArrow,
  ...IconMisc,
  ...IconProduct,
  ...IconDevelopers,
  ...IconBlog,
  ...IconDocs,
  ...IconBrand,
  ...IconRoles,
};
