import React from 'react';
import styled, { css } from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Prism as SyntaxHighlighterProps } from 'react-syntax-highlighter';

interface ResponseProps {
  title?: string;
}

const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  p {
    margin: 0;
  }
`;

const TabNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;
`;

const Tab = styled(Tabs.Trigger)`
  padding: var(--space-1) var(--space-2);
  color: var(--color-0);
  border-radius: var(--radii-pill);
  background-color: var(--color-blue-800);
  &[data-state='active'] {
    background-color: var(--color-blue-600);
  }
`;

export const CodeWrapper = styled.div`
  margin-top: 1em;

  pre[class*='language-'].line-numbers {
    padding: 0 2em;
    margin: 0 !important;
    box-shadow: none;
    background: none;
    overflow: hidden;
  }

  .line-numbers .line-numbers-rows {
    padding: 0;
  }
`;

//

const StyledViewport = styled(ScrollArea.Viewport)<any>`
  max-width: 100%;
  color: #fff;
  white-space: nowrap;
  padding-bottom: 24px;

  ${({ fullHeight }) =>
    fullHeight === 'true' &&
    css<SyntaxHighlighterProps>`
      max-height: ${(props) => props.maxHeight || '216px'};
    `}

  ${({ fullHeight }) =>
    fullHeight === 'false' &&
    css<SyntaxHighlighterProps>`
      max-height: none;
    `}
`;

const StyledScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 8px;

  z-index: 10;
  &[data-orientation='vertical'] {
    width: SCROLLBAR_SIZE;
    flex-direction: row;
    height: auto;
    width: 20px;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: SCROLLBAR_SIZE;
    height: 20px;
  }
`;

const StyledThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: rgba(var(--rgb-white), 0.5);
  box-shadow: 0 0 0 1px rgba(var(--rgb-white), 0.65);
  border-radius: var(--radii-pill);
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    z-index: 10;
  }
`;

export const MarkdownResponse: React.FC<ResponseProps> = (props) => {
  return (
    <Tabs.Root defaultValue="response-200">
      <Tabs.List>
        <Top>
          <Heading size="200" weight="semibold" color="white">
            {props?.title ?? 'Response'}
          </Heading>
          {!props?.title && (
            <TabNavigation>
              <Tab value="response-200">
                <Text size="100" color="inherit">
                  200
                </Text>
              </Tab>
              <Tab value="response-400">
                <Text size="100" color="inherit">
                  400
                </Text>
              </Tab>
            </TabNavigation>
          )}
        </Top>
      </Tabs.List>

      <Tabs.Content value="response-200">
        <CodeWrapper>
          <ScrollArea.Root>
            <StyledViewport {...props}>{props.children}</StyledViewport>
            <StyledScrollbar orientation="horizontal">
              <StyledThumb />
            </StyledScrollbar>
            <StyledScrollbar orientation="vertical">
              <StyledThumb />
            </StyledScrollbar>
          </ScrollArea.Root>
        </CodeWrapper>
      </Tabs.Content>

      <Tabs.Content value="response-400">
        <CodeWrapper>
          <pre className="language-json line-numbers">
            <code className="language-json">
              {JSON.stringify(
                {
                  type: 'NONE',
                  code: 'unknown',
                  message: 'string',
                  documentation_url: 'string',
                  details: {
                    additional_properties: 'string',
                  },
                },
                null,
                2
              )}
            </code>
          </pre>
        </CodeWrapper>
      </Tabs.Content>
    </Tabs.Root>
  );
};
