import React from 'react';
import classNames from 'classnames';
import { IconBase, IconProps } from '../Base';

export const IconDocsInternationalWire: React.FC<IconProps> = (props) => {
  const staticClasses: StaticClasses = ['icon-docs', props.className];

  return (
    <IconBase viewportSize={20} {...props} className={classNames(staticClasses)}>
      <path
        opacity="0.3"
        d="M9.9999 3C8.7299 3 7.6499 4.95 7.2499 7.67H3.3999C4.3599 4.95 6.9499 3 9.9999 3Z"
        fill="url(#paint0_linear_1194_40338)"
      />
      <path
        opacity="0.9"
        d="M16.6 7.67H12.75C12.35 4.95 11.27 3 10 3C13.05 3 15.64 4.95 16.6 7.67Z"
        fill="url(#paint1_linear_1194_40338)"
      />
      <path
        opacity="0.6"
        d="M9.9999 17.0001C6.9499 17.0001 4.3599 15.0501 3.3999 12.3301H7.2499C7.6499 15.0501 8.7299 17.0001 9.9999 17.0001Z"
        fill="url(#paint2_linear_1194_40338)"
      />
      <path
        d="M16.6 12.3301C15.64 15.0501 13.05 17.0001 10 17.0001C11.27 17.0001 12.35 15.0501 12.75 12.3301H16.6Z"
        fill="url(#paint3_linear_1194_40338)"
      />
      <path
        opacity="0.5"
        d="M12.75 7.67H7.25C7.65 4.95 8.73 3 10 3C11.27 3 12.35 4.95 12.75 7.67Z"
        fill="url(#paint4_linear_1194_40338)"
      />
      <path
        opacity="0.8"
        d="M12.75 12.3301C12.35 15.0501 11.27 17.0001 10 17.0001C8.73 17.0001 7.65 15.0501 7.25 12.3301H12.75Z"
        fill="url(#paint5_linear_1194_40338)"
      />
      <path
        d="M17 9.99992C17 10.8199 16.86 11.5999 16.6 12.3299H12.75C12.86 11.5999 12.92 10.8099 12.92 9.99992C12.92 9.18992 12.86 8.39992 12.75 7.66992H16.6C16.86 8.39992 17 9.18992 17 9.99992Z"
        fill="url(#paint6_linear_1194_40338)"
      />
      <path
        opacity="0.8"
        d="M12.9201 9.99992C12.9201 10.8199 12.8601 11.5999 12.7501 12.3299H7.25008C7.14008 11.5999 7.08008 10.8099 7.08008 9.99992C7.08008 9.18992 7.14008 8.39992 7.25008 7.66992H12.7501C12.8601 8.39992 12.9201 9.18992 12.9201 9.99992Z"
        fill="url(#paint7_linear_1194_40338)"
      />
      <path
        opacity="0.5"
        d="M7.08 9.99992C7.08 10.8199 7.14 11.5999 7.25 12.3299H3.4C3.14 11.5999 3 10.8099 3 9.99992C3 9.18992 3.14 8.39992 3.4 7.66992H7.25C7.14 8.39992 7.08 9.18992 7.08 9.99992Z"
        fill="url(#paint8_linear_1194_40338)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1194_40338"
          x1="3.5499"
          y1="8.96"
          x2="15.3899"
          y2="-2.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1194_40338"
          x1="4.93"
          y1="13.7"
          x2="16.77"
          y2="1.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1194_40338"
          x1="6.6999"
          y1="27.1801"
          x2="6.6999"
          y2="12.6401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1194_40338"
          x1="13.3"
          y1="27.1801"
          x2="13.3"
          y2="12.6401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1194_40338"
          x1="3.5"
          y1="12.91"
          x2="15.34"
          y2="1.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1194_40338"
          x1="10"
          y1="27.1801"
          x2="10"
          y2="12.6401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1194_40338"
          x1="5.38"
          y1="19.2899"
          x2="16.71"
          y2="7.95992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1194_40338"
          x1="1.61008"
          y1="18.3899"
          x2="12.9401"
          y2="7.05992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1194_40338"
          x1="-0.41"
          y1="15.7399"
          x2="10.92"
          y2="4.40992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E4199" stopOpacity="0" />
          <stop offset="0.99" stopColor="#1E4199" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
