import React from 'react';
import { Icon } from '~/elements/Icon';

export const Infrastructure = [
  {
    icon: <Icon.ProductACH />,
    iconInverted: <Icon.ProductACH inverted />,
    name: 'ACH',
    shortDescription: 'Full access to ACH rails',
    description:
      'Originate ACH credits and debits with a single API request. Utilize all five settlement windows, configure every NACHA option and control every step.',
    href: '/ach',
  },
  {
    icon: <Icon.ProductWire />,
    iconInverted: <Icon.ProductWire inverted />,
    name: 'Wire',
    shortDescription: 'Complete control of FedWire',
    description:
      'Transfer funds immediately to any bank account through FedWire via API. Receive domestic and international wires with instant updates and all the raw data.',
    href: '/wire',
  },
  {
    icon: <Icon.ProductBookTransfers />,
    iconInverted: <Icon.ProductBookTransfers inverted />,
    name: 'Book Transfers',
    shortDescription: 'Instant transfers - 24/7',
    description: 'When you own both accounts at Column, transfers are real-time every second of the day.',
    href: '/book-transfers',
  },
  {
    icon: <Icon.ProductLedger />,
    iconInverted: <Icon.ProductLedger inverted />,
    name: 'Ledger',
    shortDescription: 'A scalable system of record',
    description:
      'Ledger each transaction automatically onto our system of record. Complete visibility and full banking functionality with the ease of an API.',
    href: '/ledger',
  },
  {
    icon: <Icon.ProductBankAccounts />,
    iconInverted: <Icon.ProductBankAccounts inverted />,
    name: 'Bank Accounts',
    shortDescription: 'Fully programmable FDIC-insured accounts',
    description:
      'Spin up programmable and scalable FDIC insured bank accounts for your customers in one API call. All from our robust, easy to use developer platform.',
    href: '/bank-accounts',
  },
  {
    icon: <Icon.ProductCardPrograms />,
    iconInverted: <Icon.ProductCardPrograms inverted />,
    name: 'Card Programs',
    shortDescription: 'Customizable card network access',
    description:
      'Build a customized card program that fits your stage. With a dedicated BIN and the choice of issuing processors, you maintain control.',
    href: '/card-programs',
  },
  {
    icon: <Icon.ProductChecks />,
    iconInverted: <Icon.ProductChecks inverted />,
    name: 'Checks',
    shortDescription: 'Automatic check acceptance and processing',
    description:
      'Take full control of the end to end check flows. From RDC to checkbooks to positive-pay, we allow you to build your optimal check flow and own the entire process.',
    href: '/checks',
  },
  {
    icon: <Icon.ProductFedNow />,
    iconInverted: <Icon.ProductFedNow inverted />,
    name: 'FedNow',
    shortDescription: 'The newest payment rail from the Federal Reserve',
    description: 'The Federal Reserve’s new real-time payment rail. Instant, transparent, and available 24/7/365.',
    href: '/fednow',
  },
  {
    icon: <Icon.ProductInternationalWires />,
    iconInverted: <Icon.ProductInternationalWires inverted />,
    name: 'International Wires',
    shortDescription: 'Direct access to Swift and international routing',
    description:
      'Direct access to Swift and international routing. Send money to over 180 countries in a single API call — with full transparency.',
    href: '/international-wires',
  },
];
