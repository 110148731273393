import React from 'react';

export const ConfigTier = [
  {
    param: 'fixed_rate',
    type: 'string',
    description: <p>This tier uses a fixed interest rate (e.g., <code>"0.045"</code> means <code>4.50%</code>, only zero or positive rates are allowed). </p>,
  },
  {
    param: 'pivot_percentage',
    type: 'string',
    description: <p>This tier uses a percentage of the pivot rate (e.g., <code>"0.85"</code> means <code>85%</code> of the pivot rate, only zero or positive percentages are allowed).</p>,
  },
  {
    param: 'pivot_relative',
    type: 'string',
    description: <p>This tier uses a premium or discount to the pivot rate (e.g., <code>"-0.015"</code> means pivot rate minus <code>1.50%</code>).</p>,
  },
  {
    param: 'threshold',
    type: 'string',
    required: true,
    description: <p>The threshold of this tier in the smallest unit of the account currency (e.g, <code>100,000</code>  in <code>USD</code> would be represented as <code>"10000000"</code>).</p>,
  },
];
